/* eslint-disable no-shadow */
import {IS_BROWSER} from 'common/constants';
import theme, {breakpoint} from 'common/theme';
import {useEffect} from 'react';
import styled from 'styled-components';
import {WaveLoading} from 'styled-spinkit';

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  z-index: 5;
  position: fixed;
  top: 0;
  background-color: ${({theme}) => theme.colors.white};
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${({theme}) => theme.spacing(1)}px;
  padding-right: ${({theme}) => theme.spacing(1)}px;
  padding-top: ${({theme}) => theme.spacing(1)}px;
  padding-bottom: ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    padding-left: ${({theme}) => theme.spacing(2)}px;
    padding-right: ${({theme}) => theme.spacing(2)}px;
    padding-top: ${({theme}) => theme.spacing(2)}px;
    padding-bottom: ${({theme}) => theme.spacing(2)}px;
  `}
  ${breakpoint.size('md')`
    padding-top: ${({theme}) => theme.spacing(3)}px;
    padding-bottom: ${({theme}) => theme.spacing(3)}px;
  `};
`;

const Loading = (): JSX.Element => {
  useEffect(() => {
    if (IS_BROWSER) {
      window?.document?.body?.classList?.add('disable-scroll');
      return () => window?.document?.body?.classList?.remove('disable-scroll');
    }
  }, []);
  return (
    <Wrapper>
      <WaveLoading color={theme.colors.blue} size={theme.spacing(3)} />
    </Wrapper>
  );
};

export default Loading;
